






































































import {
	Vue,
	Component
} from 'vue-property-decorator'
import {
	apiStorageIndex,
	apiStorageSetup,
} from '@/api/setting/storage/storage'
@Component({
	components: {},
})
export default class Storage extends Vue {
	/** S Data **/
	// 存储方式
	engine = "" // 存储引擎名称：local-本地，qiniu-七牛云,aliyun-阿里云OSS,qcloud-腾讯云OS:
	form = {
		bucket: '',
		access_key: '',
		secret_key: '',
		domain: '',
		region: '', // 腾讯云需要
		status: 0,
	}

	formRules = {
		bucket: [{
			required: true,
			message: '请输入存储空间名称',
			trigger: 'blur'
		}],
		access_key: [{
			required: true,
			message: '请输入ACCESS_KEY',
			trigger: 'blur'
		}],
		secret_key: [{
			required: true,
			message: '请输入SECRET_KEY',
			trigger: 'blur'
		}],
		domain: [{
			required: true,
			message: '请输入空间域名',
			trigger: 'blur'
		}],
		region: [{
			required: true,
			message: '请输入REGION',
			trigger: 'blur'
		}],
	}
	$refs!: {
		formRef: any
	}
	/** E Data **/

	/** S Methods **/
	onSubmit() {
		// 验证表单格式是否正确
		this.$refs.formRef.validate((valid: boolean): any => {
			if (!valid) return;
			// 请求发送
			 apiStorageSetup({
				...this.form,
				engine: this.engine
			})
			.then((res: any) => {
				setTimeout(() => this.$router.go(-1), 500)			
				//this.$message.success('保存成功!')
			})
			.catch((res: any) => {
				console.log(res)
				//this.$message.error('保存失败!')
			})
			// setTimeout(()=> {
			// 	this.getStorageIndex()
			// }, 100)
		})
	}

	// 获取引擎配置
	getStorageIndex() {
		apiStorageIndex({engine: this.engine})
		.then((res: any) => {
			console.log(res)
			this.form = res
		})
		.catch((res: any) => {
			console.log(res)
			//this.$message.error('获取失败，请重试!')
		})
	}
	/** E Methods **/
	/** S Life Cycle **/
	created() {
		const query: any = this.$route.query
		if (query.engine) this.engine = query.engine
		this.getStorageIndex()
	}
	/** E Life Cycle **/
}
